import { useState } from "react";
import { useVinfoModal } from "../../../../utils/Hooks";
import { ENUMS, Layout } from "../../../../utils";
import { ExpandedDetail, SimpleDetail } from "./layout";
import { sadOutline } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { Modal } from "../../../../components";

import "./Vehicle.scss";

export const VehDetail = (page: VINFO.Page) => {
	const [expanded, setExpanded] = useState(false);
	const inventory: VINFO.Inventory = page?.vinfo?.inventory ?? null;
	const modalType = page.viewType === ENUMS.AppViewType.desktop ? ENUMS.VinfoModal.default : ENUMS.VinfoModal.sheet;
	const modalProps = useVinfoModal(modalType, page.viewType === ENUMS.AppViewType.desktop ? {} : { initialBreakpoint: 0.89, breakpoints: [0, 0.89, 1] });
	const layout: VINFO.DetailLayout = {
		...page.vinfo,
		setExpanded: () => setExpanded(false)
	}

	if (!inventory) return <InvError />;
	else return (
		<div className={Layout.VinfoBlock(page.viewType, "shaded rounded transparent vehicle-detail")}>
			<Modal id="VehicleDetailModal" viewType={page.viewType} useCloseBtn={true} modalProps={modalProps} isOpen={expanded} onClose={() => setExpanded(false)}>
				<div className="vehicle explanded">
					<div className={Layout.VinfoBlock(page.viewType, "shaded vehicle-detail")}>
						<ExpandedDetail {...layout} />
					</div>
				</div>
			</Modal>
			<SimpleDetail {...layout} />
			<div className="block see-more">
				<span className="toggle" onClick={() => setExpanded(!expanded)}>see more</span>
			</div>
		</div>
	);
};

const InvError = () => {
	return (
		<div className="block shaded rounded inv-error">
			<IonIcon size="large" icon={sadOutline} /> No Inventory Details Available
		</div>
	)
}
