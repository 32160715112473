import { IonAvatar, IonImg } from "@ionic/react";
import { useEffect, useState, useRef } from "react";

export const useTypeWriter = (txt: string, elem: string, speed: number = 10) => {
    const [hasTyped, setHasTyped] = useState(false);
    useEffect(() => {
        if (!hasTyped) {
            setTimeout(() => {
                const element = document.getElementById(elem);
                if (element) {
                    for (let i = 0; i < txt.length; i++) {
                        setTimeout(() => {
                            element.innerHTML += txt[i];
                            if (i + 1 === txt.length) setHasTyped(true);
                        }, speed * i);
                    }
                }
            }, 10);
        }
    }, []);
    return hasTyped;
}

export const useSeeMoreLink = (id: string, text: string, init: boolean, more?: boolean) => {
    return useEffect(() => {
        if (init) {
            const seemore = (text: string) => text?.match("seemore")?.[0];
            if (more && seemore(text) === "seemore") {
                const text = document.getElementById(id);
                if (text?.innerText) {
                    text.innerText = text?.innerText?.replace("seemore", "");
                    const seemore = document.createElement("span");
                    seemore.setAttribute("id", "seemore");
                    seemore.innerText = "see more";
                    text.insertAdjacentElement("beforeend", seemore);
                }
            }
        }
    }, [init]);
};

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => { ref.current = value });
    return ref.current;
};

export const useWelcomeMessage = (settings: VINFO.Settings, message: string): boolean => {
    return message.length > 0 && settings.salesperson_visible;
};

export const useSalesAvatar = (url: string) => {
    const placeholder = "/assets/images/avatar-placeholder.svg";
    if (!url) return <IonImg src={placeholder} alt="Salesperson" />;
    else
        return (
            <IonAvatar>
                <img src={url.includes("sales-contacts") ? url : placeholder} alt="Salesperson" />
            </IonAvatar>
        );
};