import { IonAvatar, IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import { locationOutline, mailOutline, phonePortraitOutline, callOutline } from "ionicons/icons";
import { useState } from "react";
import { Modal } from "../../../../components";
import { Divider } from "../../../../components/Divider";
import { ENUMS, Helpers } from "../../../../utils";
import { getImgRoot, useVinfoModal } from "../../../../utils/Hooks";

import "./ContactUs.scss";

interface ContactUsBtn extends VINFO.Page {
    useBtn?: boolean;
}

export const ContactUs = ({ viewType, vinfo, useBtn = false }: ContactUsBtn) => {
    const { share } = vinfo;
    const [isOpen, setIsOpen] = useState(false);
    const modalType = viewType === ENUMS.AppViewType.desktop ? ENUMS.VinfoModal.default : ENUMS.VinfoModal.sheet;
    const modalProps = useVinfoModal(modalType, viewType === ENUMS.AppViewType.desktop ? {} : { initialBreakpoint: 0.69, breakpoints: [0, 0.69, 1] });

    const store = vinfo?.dealership ?? null;
    const storePhoto = store?.primary_photo_url ? getImgRoot(store.primary_photo_url) : null;
    const salesPhone = share.salesperson.sms_phone ?? share.salesperson.phone;
    const salesPhoneNumber = salesPhone ? parseInt(salesPhone.replace(/[^\d\+]/g, ''), 10) : null;

    return (
        <>
            <Modal id="ContactUsModal" viewType={viewType} useCloseBtn={false} modalProps={modalProps} isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <div id="ContactOpts" className="flexblock cols stretch">
                    <Divider size="large" text="Contact Us" />
                    <div id="dealership">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="3"><IonAvatar>{storePhoto ? <img alt={store.name} src={storePhoto} /> : <span />}</IonAvatar></IonCol>
                                <IonCol size="9">
                                    <h5 className="creds">
                                        <span className="name">{store?.name}</span>
                                        <span className={`job ${store.schedule.open_now ? "green" : "red"}`}>{store.schedule.open_now ? "open now" : "closed"}</span>
                                    </h5>
                                    <div className="methods flexblock cols gap-ten">
                                        <div className="flexblock gap-ten aln-cnt">
                                            <IonIcon icon={locationOutline} size="large" />
                                            <div className="contact">
                                                {store.fullAddress}
                                            </div>
                                        </div>
                                        <div className="flexblock gap-ten aln-cnt">
                                            <IonIcon icon={callOutline} />
                                            <div className="contact">
                                                <a href={`tel://+${store.phone}`} className="phone">{Helpers.formatPhone(store.phone)}</a>
                                            </div>
                                        </div>
                                        {store.sales_email && <div className="flexblock gap-ten aln-cnt">
                                            <IonIcon icon={mailOutline} />
                                            <div className="contact">
                                                {store.sales_email}
                                            </div>
                                        </div>}
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                    <Divider text="SALESPERSON" />
                    <div id="salesperson">
                        <IonGrid>
                            <IonRow>
                                <IonCol size="3"><IonAvatar><img src={share.salesperson.photo_url} alt="Salesperson" /></IonAvatar></IonCol>
                                <IonCol size="9">
                                    <h5 className="creds">
                                        <span className="name">{share.salesperson.name}</span>
                                        <span className="job">{share.salesperson.job_title_name}</span>
                                    </h5>
                                    <div className="methods flexblock cols gap-ten">
                                        {share.salesperson.email && <div className="flexblock gap-ten aln-cnt">
                                            <IonIcon icon={mailOutline} size="large" />
                                            <div className="contact">
                                                {share.salesperson.email}
                                            </div>
                                        </div>}
                                        <div className="flexblock gap-ten aln-cnt">
                                            <IonIcon icon={phonePortraitOutline} size="large" />
                                            <div className="contact">
                                                <a href={`tel://+${salesPhoneNumber}`} className="phone">{Helpers.formatPhone(salesPhoneNumber)}</a>
                                            </div>
                                        </div>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </div>
                    <IonButton color="primary" expand="block" onClick={() => setIsOpen(false)}>Close</IonButton>
                </div>
            </Modal>
            {useBtn ? (
                <div id="Directions" className="block rounded btn secondary" onClick={() => setIsOpen(true)}>
                    <IonIcon icon={callOutline} />
                    <span>Contact Us</span>
                </div>
            ) : <IonIcon icon={callOutline} size="large" onClick={() => setIsOpen(true)} />}
        </>
    )
}