import { useState } from "react";
import { useDarkMode, useGeom } from "../../Hooks";
import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonGrid, IonIcon, IonImg, IonRow } from "@ionic/react";
import { ENUMS, Helpers, Layout } from "../../../../utils";
import { getImgRoot, useFancyGrad, useVinfoModal } from "../../../../utils/Hooks";
import { Carousel, Map, Modal } from "../../../../components";
import { locationOutline, mailOutline, phonePortraitOutline, logoFacebook, logoInstagram, logoLinkedin, logoTwitter, navigateCircleOutline } from "ionicons/icons";

import "./Dealership.scss";

interface DealershipLayout extends VINFO.Page {
    openPhotos: (open: boolean) => void;
    openMap: (open: boolean) => void;
}

export const DealerBtn = (layout: VINFO.Layout) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openPhotos, setIsOpenPhotos] = useState(false);
    const [openMaps, setIsOpenMaps] = useState(false);

    const store = layout?.page?.vinfo?.dealership ?? null;
    const photos = store?.store_photos ?? [];

    const mapsProps = useVinfoModal(ENUMS.VinfoModal.default);
    const modalProps = useVinfoModal(ENUMS.VinfoModal.default, { cssClass: "large" });

    const coords = useGeom(store.geom);
    const prefersDark = useDarkMode(layout.page.vinfo.theme);
    useFancyGrad(["DealerBtn"]);

    return (
        <>
            <div id="DealerTile" className={Layout.VinfoBlock(layout.page.viewType)}>
                <Modal id="MapModal" viewType={layout.page.viewType} isOpen={openMaps} modalProps={mapsProps} onClose={() => setIsOpenMaps(false)}>
                    <Map location={{ coords, store: layout.page.vinfo.dealership }} />
                </Modal>
                <Modal id="DealerModal" viewType={layout.page.viewType} isOpen={isOpen} modalProps={modalProps} onClose={() => setIsOpen(false)}>
                    <Dealership {...layout.page} openMap={open => setIsOpenMaps(open)} openPhotos={open => setIsOpenPhotos(open)} />
                </Modal>
                <div id="DealerBtn" className="block shaded rounded btn grad-btn info-btn" onClick={() => setIsOpen(true)}>
                    <IonImg className="icon" src={Layout.SectionIcon(ENUMS.VinfoSection.dealership, layout.prefersDark)} />
                    <span>Dealership Info</span>
                </div>
            </div>
            {openPhotos && (
                <Carousel
                    items={photos}
                    type={ENUMS.VinfoCarousel.max}
                    itemkey="full_url"
                    preference={prefersDark ? "dark" : "light"}
                    altkeys={["full_url"]}
                    onClose={() => setIsOpenPhotos(false)}
                />
            )}
        </>
    )
}

export const DealerSection = (layout: VINFO.Layout) => {
    const [openPhotos, setIsOpenPhotos] = useState(false);
    const [openMaps, setIsOpenMaps] = useState(false);

    const store = layout?.page?.vinfo?.dealership ?? null;
    const photos = store?.store_photos ? store.store_photos : store?.primary_photo_url ? [{ id: 0, full_url: getImgRoot(store.primary_photo_url) }] : [];
    const mapsProps = useVinfoModal(ENUMS.VinfoModal.sheet);
    const photosProps = useVinfoModal(ENUMS.VinfoModal.default);
    const prefersDark = useDarkMode(layout.page.vinfo.theme);
    const coords = useGeom(store.geom);

    return (
        <div>
            <Modal id="MapModal" viewType={layout.page.viewType} isOpen={openMaps} modalProps={mapsProps} onClose={() => setIsOpenMaps(false)}>
                <Map location={{ coords, store: layout.page.vinfo.dealership }} />
            </Modal>
            <Modal id="DealerPhotos" viewType={layout.page.viewType} isOpen={openPhotos} modalProps={photosProps} onClose={() => setIsOpenPhotos(false)}>
                <div className="flexblock fill center">
                    <Carousel type={ENUMS.VinfoCarousel.swipe} items={photos} preference={prefersDark ? "dark" : "light"} altkeys={["full_url"]} itemkey="full_url" />
                </div>
            </Modal>
            <Dealership {...layout.page} openMap={open => setIsOpenMaps(open)} openPhotos={open => setIsOpenPhotos(open)} />
        </div>
    )
}


const Dealership = (page: DealershipLayout) => {
    const store = page?.vinfo?.dealership ?? null;
    const photo = store?.primary_photo_url ? getImgRoot(store.primary_photo_url) : null;
    const isDesktop = page.viewType === ENUMS.AppViewType.desktop;
    if (!store) return <span />;
    else return (
        <div id="Dealership" className={Layout.VinfoBlock(page.viewType, "grow")}>
            {photo && <div className="item-photo">
                <img alt={store.name} src={photo} onClick={() => page.openPhotos(true)} />
            </div>}
            <div className={Layout.VinfoBlock(page.viewType)}>
                <h1 className="store-name">{store?.name}</h1>
            </div>
            <div id="StoreInfo" className={Layout.VinfoBlock(page.viewType, "shaded rounded")}>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <DealerInfo {...page} />
                        </IonCol>
                        {isDesktop && (
                            <IonCol>
                                <div className="flexblock stretch gap-ten aln-end">
                                    <DealerSocials size="large" social_urls={page?.vinfo?.dealership?.social_urls} />
                                </div>
                            </IonCol>
                        )}
                    </IonRow>
                </IonGrid>
            </div>
            <div id="StoreHours" className={Layout.VinfoBlock(page.viewType, "shaded rounded")}>
                <DealerHours {...page} />
            </div>
            <div id="WhyBuy" className={Layout.VinfoBlock(page.viewType, "whybuy")}>
                <DealerWhyBuy {...store} />
            </div>
            {!isDesktop && (
                <div id="Socials" className={Layout.VinfoBlock(page.viewType)}>
                    <div className="pad flexblock center gap-ten aln-cnt socials">
                        <DealerSocials size="large" social_urls={page?.vinfo?.dealership?.social_urls} />
                    </div>
                </div>
            )}
        </div>
    );
};

const DealerInfo = (page: DealershipLayout) => {
    const store = page?.vinfo?.dealership ?? null;
    return (
        <>
            {store.fullAddress && (
                <div className="flexblock gap-ten aln-cnt" onClick={() => page.openMap(true)}>
                    <IonIcon icon={locationOutline} size="large" />
                    <div className="contact">
                        {store.fullAddress}
                    </div>
                </div>
            )}
            {store.sales_email && (
                <div className="flexblock gap-ten aln-cnt">
                    <IonIcon icon={mailOutline} size="large" />
                    <div className="contact">
                        {store.sales_email}
                    </div>
                </div>
            )}
            {store.phone && (
                <div className="flexblock gap-ten aln-cnt">
                    <IonIcon icon={phonePortraitOutline} size="large" />
                    <div className="contact">
                        <a href={`tel://+${store.phone}`} className="phone">{Helpers.formatPhone(store.phone)}</a>
                    </div>
                </div>
            )}
        </>
    )
}

const DealerHours = (page: DealershipLayout) => {
    const store = page?.vinfo?.dealership ?? null;
    const today = Helpers.days[new Date().getDay()];
    if (!store.schedule?.days) return <span />;
    return (
        <div className="flexblock gap-thirty">
            <h3 className={store.schedule.open_now ? "green" : "red"}>
                Hours
                <span className="sub">{store.schedule.open_now ? "open now" : "closed"}</span>
            </h3>
            <div className="hours">
                {store.schedule.days.map(day => {
                    const isToday = day.day_text === today;
                    return (
                        <div key={day.day_text} className={`day flexblock ${isToday ? store.schedule.open_now ? "green" : "red" : ""}`}>
                            <span className="time">{day.open ? `${Helpers.getReadableHour(day.open_time)} - ${Helpers.getReadableHour(day.close_time)}` : "closed"}</span>
                            <span>{isToday ? "Today" : day.day_text}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const DealerWhyBuy = (store: VINFO.Store) => {
    const { why_us, testimonial } = store;
    if (!why_us && !testimonial) return <span />;
    return (
        <div className="flexblock rows two-col gap-ten stretch even center">
            {testimonial && (
                <div className="block shaded rounded testimonial">
                    <label>Testimonial</label>
                    <div dangerouslySetInnerHTML={{ __html: testimonial }} />
                </div>
            )}
            {why_us && (
                <div className="block shaded rounded whyus">
                    <label>Why Us?</label>
                    <div dangerouslySetInnerHTML={{ __html: why_us }} />
                </div>
            )}
        </div>
    )
}

export const DealerSocials = ({ size, social_urls }: { size: string, social_urls: any }) => {
    if (!social_urls) return <span />;
    const socials = [{ id: "linked_in", icon: logoLinkedin }, { id: "facebook", icon: logoFacebook }, { id: "twitter", icon: logoTwitter }, { id: "instagram", icon: logoInstagram }].map(i => ({ ...i, url: social_urls[i.id] })).filter(i => i.url && i.url !== "");
    return (
        <>
            {socials.map(social => {
                return (
                    <a key={social.id} className="linkicon" target="_blank" rel="noreferrer" href={social.url}>
                        <IonIcon icon={social.icon} size={size} />
                    </a>
                )
            })}
        </>
    )
}

export const DealerDetailCard = ({ store }: VINFO.DealerSimpleDetail) => {
    const [activeView, setActiveView] = useState(ENUMS.DealerSimpleActiveView.mapLocation);
    const storePhoto = store?.primary_photo_url ? getImgRoot(store.primary_photo_url) : null;
    const coords = useGeom(store.geom);

    const viewMap = activeView === ENUMS.DealerSimpleActiveView.mapLocation;
    const directionsURL = `https://www.google.com/maps/dir/Current+Location/${coords.lat},${coords.lng}`;

    return (
        <div id="DealerSimple">
            <Map location={{ coords, store }} offset={{ x: 200, y: 100 }} />
            <div className="map-tools">
                <div className="store-info">
                    <IonCard>
                        {viewMap && storePhoto ? (
                            <img className="store-photo" alt={store.name} src={storePhoto} onClick={() => setActiveView(ENUMS.DealerSimpleActiveView.storePhotos)} />
                        ) : (
                            <span />
                        )}
                        <IonCardHeader>
                            <img className="store-logo" alt={`${store.name} logo`} src={store.logo_url} />
                            <IonCardTitle>{store.name}</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <div className="flexblock gap-ten aln-cnt">
                                <IonIcon icon={locationOutline} />
                                <div className="contact">
                                    {store.fullAddress}
                                </div>
                            </div>
                            <div className="flexblock gap-ten aln-cnt">
                                <IonIcon icon={mailOutline} />
                                <div className="contact">
                                    {store.sales_email}
                                </div>
                            </div>
                            <div className="flexblock gap-ten aln-cnt">
                                <IonIcon icon={phonePortraitOutline} />
                                <div className="contact">
                                    {Helpers.formatPhone(store.sales_phone)}
                                </div>
                            </div>
                            <div className="cta">
                                <div id="DirectionsBtn" className="block shaded rounded btn primary" onClick={() => window.open(directionsURL)}>
                                    <IonIcon icon={navigateCircleOutline} />
                                    <span className="btn-text">Open Directions in Google Maps</span>
                                </div>
                            </div>
                        </IonCardContent>
                    </IonCard>
                </div>
            </div>
        </div>
    )
}
