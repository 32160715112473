import { useCallback, useEffect, useState } from "react";
import { Helpers } from "../utils";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from "@react-google-maps/api";
import { IonLoading } from "@ionic/react";

export const Map = ({ location, zoom = 18, label, offset }: VINFO.Mapski) => {
    const { isLoaded } = useJsApiLoader(Helpers.googleMaps);
    const [center, setCenter] = useState<any>(null);
    const [map, setMap] = useState<any>(null);

    useEffect(() => {
        if (location.coords) setCenter(location.coords);
    }, [location]);

    useEffect(() => {
        if (map && offset) map.panBy(offset.x, offset.y);
    }, [map, offset])

    const onLoad = useCallback(map => {
        setMap(map);
    }, [center]);

    const mapProps = {
        center,
        zoom,
        onLoad,
        mapContainerStyle: { width: "100%", height: "70vh" },
    }

    return isLoaded && center ? (
        <div id="Map" className="vinfo-map">
            <GoogleMap {...mapProps}>
                <Marker position={center}>
                    {label ? <InfoWindow options={{ position: center, pixelOffset: new window.google.maps.Size(0, -30) }}>{label()}</InfoWindow> : ""}
                </Marker>
            </GoogleMap>
        </div>
    ) : <IonLoading isOpen={true} />;
}
