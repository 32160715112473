import { useEffect, useState } from "react";
import { useTypeWriter, useSeeMoreLink } from "../Hooks";
import "./Comment.scss";

interface CommentProps {
    text: string;
    duration?: number;
    more?: boolean;
    callback?: () => void;
}

export const Comment = (props: CommentProps) => {
    const [kickoff, setKickoff] = useState(false);
    if (!kickoff) return <Typing duration={props.duration} callback={() => setKickoff(true)} />
    return <Context {...props} />;
}

const Context = ({ text, duration, more, callback }: CommentProps) => {
    const id = "CustomerMessageText";
    const hasTyped = useTypeWriter(text, id, duration ? 10 : 0);
    useSeeMoreLink(id, text, hasTyped, more);
    return <span id={id} onClick={() => callback?.()}></span>;
}

const Typing = ({ callback, duration = 15000 }: { duration?: number, callback: () => void }) => {
    useEffect(() => {
        let mounted = true;
        setTimeout(() => { if (mounted) callback() }, duration);
        return () => { mounted = false };
    });
    return (
        <div className="typing active" onClick={() => callback()}>
            <span className="typing__bullet"></span>
            <span className="typing__bullet"></span>
            <span className="typing__bullet"></span>
        </div>
    )
}