import { IonIcon, IonImg } from "@ionic/react";
import { compassOutline } from "ionicons/icons";
import { useGeom } from "../../Hooks";
import { ContactUs } from "../ContactUs/ContactUs";

import "./Header.scss";

export const Header = (page: VINFO.Page) => {
    const { vinfo } = page;
    const coords = useGeom(vinfo.dealership.geom);
    const directions = `https://www.google.com/maps/dir/Current+Location/${coords.lat},${coords.lng}`;
    return (
        <div id="MobileHeader">
            <div className="flexblock rows stretch gap-ten aln-cnt">
                <IonImg src={vinfo.dealership.logo_url} className="store_logo" />
                <div className="dealer-cta">
                    <IonIcon icon={compassOutline} size="large" onClick={() => window.open(directions)} />
                    <ContactUs {...page} />
                </div>
            </div>
        </div>
    );
}
