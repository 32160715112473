import { IonIcon, IonModal } from "@ionic/react";
import { closeCircleOutline } from "ionicons/icons";

import "./Components.scss";

export const Modal = ({ id, isOpen, viewType, size = null, useCloseBtn = true, children, modalProps, onClose }: APP.Modal) => {
    return (
        <IonModal {...modalProps} id={id} className={`${viewType} ${size ? size : ""}`} isOpen={isOpen} onDidDismiss={() => onClose()}>
            <div className="modal-content">
                <div className="tools">
                    {useCloseBtn && <IonIcon icon={closeCircleOutline} size="large" onClick={() => onClose()} />}
                </div>
                <div className="content">
                    {children}
                </div>
            </div>
        </IonModal>
    )
}