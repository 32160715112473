import { Helpers } from "../../../../../utils";
import { Price } from "./price";

export const SimpleDetail = (detail: VINFO.DetailLayout) => {
	const { inventory } = detail;
	return (
		<>
			<div className="flexblock stretch gap-ten">
				<h1 className="specs">
					<span className="sub">
						{inventory.condition} {inventory.year} {inventory.make}
					</span>
					{inventory.model} {inventory.trim}
				</h1>
				<span className="spec">
					<span className="label">ODO: </span> {Helpers.formatNumber(inventory.miles)}
				</span>
			</div>
			<div className="flexblock stretch gap-ten aln-btm">
				<span className="spec ids">
					<span className="label">VIN:</span> {inventory.vin}
					<br />
					<span className="label">STK:</span> {inventory.stock_number}
				</span>
				<Price {...detail} />
			</div>
		</>
	);
};
