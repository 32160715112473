import { IonAvatar, IonIcon, IonImg } from "@ionic/react";
import { closeCircleOutline, mailOutline, phonePortraitOutline } from "ionicons/icons";
import { useEffect, useState } from "react";
import { Modal } from "../../../../components";
import { ENUMS, Helpers, Layout } from "../../../../utils";
import { getImgRoot, useFancyGrad, useVinfoModal } from "../../../../utils/Hooks";
import { Comment } from "./Comment/Comment";
import { usePrevious, useWelcomeMessage, useSalesAvatar } from "./Hooks";
import "./Salesperson.scss";

export const SalesPersonBtn = (layout: VINFO.Layout) => {
	const [isOpen, setIsOpen] = useState(false);
	const modalProps = useVinfoModal(ENUMS.VinfoModal.default, { cssClass: "large" });
	useFancyGrad(["SalesBtn"]);

	return (
		<div id="SalespersonTile" className={Layout.VinfoBlock(layout.page.viewType)}>
			<Modal id="SalesmanModal" viewType={layout.page.viewType} isOpen={isOpen} modalProps={modalProps} onClose={() => setIsOpen(false)}>
				<Salesperson {...layout.page} />
			</Modal>
			<div id="SalesBtn" className="block shaded rounded btn grad-btn info-btn" onClick={() => setIsOpen(true)}>
				<IonImg className="icon" src={Layout.SectionIcon(ENUMS.VinfoSection.salesperson, layout.prefersDark)} />
				<span>Salesperson Info</span>
			</div>
		</div>
	);
};

export const SalespersonMessage = (page: VINFO.Page) => {
	const [key, setKey] = useState(new Date().toString());
	const [hide, setHide] = useState(false);
	const [full, setFull] = useState(false);
	const [typingDuration, setTypingDuration] = useState(3000);
	const hasPrevious = usePrevious({ full });
	const isDesktop = page.viewType === ENUMS.AppViewType.desktop;

	const share = page?.vinfo?.share ?? null;
	const avatar = useSalesAvatar(share?.salesperson?.photo_url);
	const shouldWelcome = useWelcomeMessage(page?.vinfo?.settings, share.customer_message);
	const length = window.innerWidth <= 1500 ? 75 : 85;

	const commentText = full || !isDesktop ? share.customer_message : `${share.customer_message.substring(0, length)}... seemore`;

	useEffect(() => {
		// once the inital load is done, re-rendering comment without typing duration
		if (hasPrevious) {
			setKey(new Date().toString());
			setTypingDuration(0);
		}
	}, [full, hasPrevious]);

	const clostoff = () => {
		if (isDesktop) setFull(false);
		else setHide(true);
	};

	if (hide || !share || !shouldWelcome) return <span />;
	return (
		<>
			{full || !isDesktop ? <div className={isDesktop ? "backdrop" : "no-backdrop"} onClick={() => clostoff()} /> : ""}
			<div id="SalespersonMessage" className={`flexblock stretch ${isDesktop ? (full ? "full-size" : "compact") : "fix-btm"}`}>
				<div className="flexblock gap-thirty aln-end">{avatar}</div>
				<div className="flexblock cols stretch comment-block">
					<div className={`comment`}>
						<IonIcon className="close" icon={closeCircleOutline} onClick={() => (isDesktop && full ? setFull(false) : setHide(true))} />
						<span className="speak" />
						<div className={`message`}>
							<Comment
								key={key}
								text={commentText}
								duration={typingDuration}
								more={isDesktop && !full}
								callback={() => isDesktop && setFull(true)}
							/>{" "}
							<span className="says">- {share.salesperson.name}</span>
						</div>
					</div>
					{full ? <CtaLinks /> : ""}
				</div>
			</div>
		</>
	);
};

const CtaLinks = () => {
	return <span />;
};

export const Salesperson = (page: VINFO.Page) => {
	const share = page?.vinfo?.share ?? null;
	const store = page?.vinfo?.dealership ?? null;
	const photo = store?.primary_photo_url ? getImgRoot(store.primary_photo_url) : null;
	const phoneDisplay = share.salesperson.sms_phone ?? share.salesperson.phone;
	const phoneNumb = phoneDisplay ? parseInt(phoneDisplay.replace(/[^\d\+]/g, ""), 10) : null; // eslint-disable-line

	if (!share) return <span />;
	else
		return (
			<div id="Salesperson" className={Layout.VinfoBlock(page.viewType, "grow")}>
				{photo && (
					<div className="item-photo">
						<img alt={page?.vinfo?.dealership?.name} src={photo} />
					</div>
				)}
				<div id="SalesInfo" className={Layout.VinfoBlock(page.viewType)}>
					<IonAvatar>
						<img src={share.salesperson.photo_url} alt={share.salesperson.name} />
					</IonAvatar>
					<h2>{share.salesperson.name}</h2>
					<h3>{share.salesperson.job_title_name}</h3>
				</div>
				<div id="SalesContact" className={Layout.VinfoBlock(page.viewType, "shaded rounded")}>
					{share.salesperson.email && (
						<div className="flexblock gap-ten aln-cnt">
							<IonIcon icon={mailOutline} size="large" />
							<div className="contact">{share.salesperson.email}</div>
						</div>
					)}
					<div className="flexblock gap-ten aln-cnt">
						<IonIcon icon={phonePortraitOutline} size="large" />
						<div className="contact">
							<a href={`tel://+${phoneNumb}`} className="phone">
								{Helpers.formatPhone(phoneNumb)}
							</a>
						</div>
					</div>
				</div>
				{share.salesperson.bio_text && (
					<div id="SalesBio" className={Layout.VinfoBlock(page.viewType, "shaded rounded")}>
						<span dangerouslySetInnerHTML={{ __html: share.salesperson.bio_text }} />
					</div>
				)}
			</div>
		);
};
