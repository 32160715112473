export const defaultTheme = () => ({
    font_type: "google",
    font: "Open Sans",
    font_family: "sans-serif",
    bold_font: "Akshar",
    bold_font_family: "sans-serif",
    primary_color: "#ae72af",
    secondary_color: "#1dafec",
    tertiary_color: "#5260ff",
    dark_mode: null,
    display_docs: 3,
    content_width: "1440px",
});