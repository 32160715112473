import { useEffect, useState } from "react";
import { ENUMS, Helpers, Layout } from "../../utils";
import { defaultTheme } from "../../theme/defaultTheme";
import { Desktop, Mobile } from "./Layouts";

const WebFont = require('webfontloader');

const useNewVinfoUrl = ({ token, params, type }: APP.Location) => {
	const reservedRefs = process.env.REACT_APP_RESERVED_REFERRERS?.split(',');
	const reservedReft = reservedRefs?.includes(window.location.host);
	const baseUrl = process.env.REACT_APP_API_URL;
	const typeQuery = `${type ? 'type=' + type : ''}`;
	const reservedReferrerQuery = `${reservedReft ? 'is_counting=false' : 'is_counting=true'}`;
	const code = params.lot_code ? params.lot_code : params.store_code;
	const src = params.src;

	let query = !type ? reservedReferrerQuery : `${typeQuery}&${reservedReferrerQuery}`;
	if (code) query += `&lot_code=${code}`;
	if (src) query += `&src=${src}`;
	const fetchPath = type === ENUMS.VinfoType.demo ? `/demo/${token}` : `/${token}?${query}`;
	return `${baseUrl}/reports/vinfo${fetchPath}` ?? null;
}

export const useVinfoRest = (location: APP.Location): VINFO.Data => {
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState(null);
	const [error, setError] = useState<any>(null);
	const [refetch, setRefetch] = useState(false);
	const url = useNewVinfoUrl(location);

	useEffect(() => {
		const fetchData = async () => {
			if (url) {
				try {
					setLoading(true);
					const res = await fetch(url);
					const json: any = await res.json();
					if (json?.inv) {
						setData(json);
						setLoading(false);
					} else setError(json.message);
				} catch (error: any) {
					setError("There was a problem");
				}
			}
		}
		fetchData();
	}, [url, refetch]);

	const vinfoData = useVinfoData(data);
	useTheme(vinfoData?.theme);

	return { loading, data: vinfoData, error, refetch: () => setRefetch(true) };
}

const massageStoreData = (store: any) => {
	const addr = store?.address;
	const fullAddress = addr ? `${addr.address1} ${addr.address2} ${addr.city}, ${addr.state} ${addr.zip}` : "";
	return { ...store, fullAddress };
}

const useVinfoData = (data: any): VINFO.Detail | null => {
	const theme = useThemeData(data);
	if (!data) return null;
	return {
		theme,
		share: data,
		inventory: data?.inv,
		documents: data?.vehicle_documents,
		settings: data?.settings,
		dealership: massageStoreData(data?.dealership)
	};
};

export const useDarkMode = (theme?: VINFO.Theme): boolean => {
	const watch = window.matchMedia('(prefers-color-scheme: dark)');

	const usingTheme = theme?.dark_mode !== null ?? false;
	const themeSetting = theme?.dark_mode ?? false;

	const [prefersDark, setPrefersDark] = useState(usingTheme ? themeSetting : watch.matches);

	useEffect(() => {
		const callback = (dark: any) => setPrefersDark(dark.matches);
		if (!usingTheme) watch.addEventListener("change", callback);
		return () => watch.removeEventListener("change", callback);
	}, [watch, usingTheme]);

	useEffect(() => {
		document.documentElement.setAttribute("vinfo-theme", prefersDark ? "dark" : "light");
		document.getElementById("Vinfo")?.setAttribute("vinfo-theme", prefersDark ? "dark" : "light");
	}, [prefersDark])

	return prefersDark;
}

export const useLayout = (layout: VINFO.Layout) => {
	const isDesktop = layout.page.viewType === ENUMS.AppViewType.desktop;
	return <>{isDesktop ? <Desktop {...layout} /> : <Mobile {...layout} />}</>
}

const useThemeData = (data: any): VINFO.Theme => {
	return data?.settings?.theme ?? defaultTheme();
}

interface ThemeFont { type: string, config: { families: string[], urls?: string[] }, fonts: string[] };
const useFont = (theme: VINFO.Theme | undefined): ThemeFont | undefined => {
	if (!theme) return;

	const type = theme.font_type;
	let config: any = { families: [theme.font, theme.bold_font] }
	let fonts: any = [theme.font, theme.font_family, theme.bold_font, theme.bold_font_family];

	if (type === "custom") {
		if (theme.font === "AudiType-WideNormal") {
			const audi = Helpers.getAudiFont();
			config = { families: audi.families, urls: audi.urls };
			fonts = audi.fonts;
		} else {
			const mercedes = Helpers.getMercFont();
			config = { families: mercedes.families, urls: mercedes.urls };
			fonts = mercedes.fonts;
		}
	}

	return { type, config, fonts };
}

const useTheme = (theme?: VINFO.Theme) => {
	const prefersDark = useDarkMode(theme);
	const font = useFont(theme);

	useEffect(() => {
		if (!theme) return;

		// desktop gutters width
		if (theme.content_width) document.documentElement.style.setProperty("--theme-content-width", theme.content_width);

		// fonts
		if (font) {
			const { type, config, fonts } = font;
			WebFont.load({ [type]: config });
			// set font
			document.documentElement.style.setProperty("--theme-font", `${fonts[0]}, ${fonts[1]}`);
			document.documentElement.style.setProperty("--theme-bold-font", `${fonts[2]}, ${fonts[3]}`);
		}

		// colors
		const setColor = (key: string, val: any) => {
			document.documentElement.style.setProperty(key, val);
			document.documentElement.style.setProperty(`${key}-rgb`, `${Helpers.hexToRgb(val)}`);
			document.documentElement.style.setProperty(`${key}-tint`, `${Helpers.shadeColor(val, 5)}`);
			document.documentElement.style.setProperty(`${key}-shade`, `${Helpers.shadeColor(val, 10)}`);
		}
		// ----- check for colors or use defaults
		if (theme.primary_color) setColor("--ion-color-primary", theme.primary_color);
		if (theme.secondary_color) setColor("--ion-color-secondary", theme.secondary_color);
		if (theme.tertiary_color) setColor("--ion-color-tertiary", theme.tertiary_color);

	}, [theme, prefersDark, font])
}

// Section hooks for the menu
export const useSectionPositions = (section: ENUMS.VinfoSection): ENUMS.VinfoMenuPosition[] => {
	const pos = Helpers.arrayFromEnum(ENUMS.VinfoMenuPosition);
	let positions: any[] = pos;
	switch (section) {
		case ENUMS.VinfoSection.salesperson:
			positions = [pos[1], pos[2], pos[0]];
			break;
		case ENUMS.VinfoSection.dealership:
			positions = [pos[2], pos[0], pos[1]];
			break;
		default:
			break;
	}
	return positions;
}

export const useSections = (layout: VINFO.Layout) => {
	const positions = useSectionPositions(layout.section);
	const sections = Helpers.arrayFromEnum(ENUMS.VinfoSection);
	const animation = layout.page.viewType !== ENUMS.AppViewType.desktop ? "slide" : "fade";
	const slideAttr = (section: ENUMS.VinfoSection) => ({ className: Layout.VinfoBlock(layout.page.viewType, `${animation} ${positions[sections.indexOf(section)]}`) });
	return sections.map((section: any) => (slideAttr(section)));
}

export const useVehiclePhotos = (vehicle: VINFO.Inventory | undefined) => {
	let photos = vehicle?.photos ?? [];
	if (!photos.length) photos = Layout.NoPhotoAvailable("url");
	return photos;
}

export const useGeom = (geom: VINFO.StoreGeom | undefined) => {
	if (geom) {
		const { coordinates } = geom;
		return { lat: coordinates[1], lng: coordinates[0] };
	} else return { lat: -3.745, lng: -38.523 };
}

export const useScrolling = (id: string, deps: any = []) => {
	useEffect(() => {
		const content = document.getElementById(id);
		if (content) {
			content.scrollTop = 0;
			// wait for animation to finish and decide to scroll or not
			setTimeout(() => {
				const slide = content.querySelector(".slide.center")
				const slideHeight = slide?.scrollHeight;
				if (slideHeight && slideHeight >= window.innerHeight - 150) {
					content.classList.add("scrolling");
				} else content.classList.remove("scrolling");
			}, 1050);
		}
	}, [id, deps]);
}
