import { IonCol, IonGrid, IonRow } from "@ionic/react";
import { Divider } from "../../../../../components/Divider";
import { Helpers } from "../../../../../utils";
import { Price } from "./price";

export const ExpandedDetail = (detail: VINFO.DetailLayout) => {
	const { inventory } = detail;
	const usingMiles = inventory?.distance_units === "MI";
	const econCITY = usingMiles ? inventory?.city_miles_per_gallon : inventory?.city_liters_per_100_km;
	const econHWY = usingMiles ? inventory?.highway_miles_per_gallon : inventory?.highway_liters_per_100_km;

	return (
		<>
			<div className="flexblock stretch gap-ten">
				<h1 className="specs">
					<span className="sub">
						{inventory.condition} {inventory.year} {inventory.make}
					</span>
					{inventory.model} {inventory.trim}
				</h1>
				<span className="spec odo">
					<span className="label">ODO: </span>
					{Helpers.formatNumber(inventory.miles)}
				</span>
			</div>
			<div className="flexblock stretch gap-ten aln-btm">
				<span className="spec ids">
					<span className="label">VIN:</span> {inventory.vin}
					<br />
					<span className="label">STK:</span> {inventory.stock_number}
				</span>
				<Price {...detail} />
			</div>
			<div className="flexblock cols stretch">
				<br />
				<Divider size="small" text="Details" />
				<div className="deets">
					<IonGrid className="spec">
						<IonRow>
							<IonCol size="4" className="label spc">
								ENGINE:
							</IonCol>
							<IonCol size="8">{inventory.engine_description}</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="4" className="label spc">
								TRANSMISSION:
							</IonCol>
							<IonCol size="8">{inventory.transmission_name}</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="4" className="label spc">
								DRIVETRAIN:
							</IonCol>
							<IonCol size="8">{inventory.transmission_drive_type}</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="4" className="label spc">
								EXTERIOR:
							</IonCol>
							<IonCol size="8">{inventory.exterior_color_description}</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="4" className="label spc">
								INTERIOR:
							</IonCol>
							<IonCol size="8">{inventory.interior_color_description}</IonCol>
						</IonRow>
						<IonRow>
							<IonCol size="4" className="label spc">
								{usingMiles ? "MPG" : "LPK"}:
							</IonCol>
							<IonCol size="8">
								{econHWY} <span className="label">HWY</span> | {econCITY} <span className="label">CITY</span>
							</IonCol>
						</IonRow>
					</IonGrid>
				</div>
				<br />
				<Divider size="small" text="Description" />
				<br />
				<div className="description">
					<span dangerouslySetInnerHTML={{ __html: inventory.description }} />
				</div>
			</div>
		</>
	);
};
