import { compassOutline } from "ionicons/icons";
import { IonIcon, IonImg } from "@ionic/react";
import { SalespersonMessage } from "../pages/Vinfo/components/Salesperson/Salesperson";
import { FinePrint } from "../pages/Vinfo/Layouts";
import { ContactUs } from "../pages/Vinfo/components";
import { useGeom } from "../pages/Vinfo/Hooks";

export const CtaBanner = (layout: VINFO.Layout) => {
    const coords = useGeom(layout?.page?.vinfo?.dealership.geom);
    const directionsURL = `https://www.google.com/maps/dir/Current+Location/${coords.lat},${coords.lng}`;
    return (
        <div id="CTA-Banner" className={`cta-block block shaded`}>
            <SalespersonMessage {...layout.page} />
            <div className="content gutters">
                <div id="CTA" className="flexblock contain rows center aln-cnt stretch">
                    <div className="vinfo-tools flexblock rows center gap-thirty">
                        <div id="CTABtns">
                            <div id="Directions" className="block rounded btn primary directionsBtn ctactions" onClick={() => window.open(directionsURL)}>
                                <IonIcon icon={compassOutline} />
                                <span>Get Directions</span>
                            </div>
                            <div id="ContactUs" className="ctactions">
                                <ContactUs {...layout.page} useBtn={true} />
                            </div>
                        </div>
                        <IonImg className="vinfo-logo" src={`/assets/images/vinfo_logo_${layout.prefersDark ? "dark" : "light"}.svg`} />
                    </div>
                </div>
                <FinePrint />
            </div>
        </div>
    )
}