import { useMemo, useState } from "react";
import { ENUMS, Layout } from "../../utils";
import { useDarkMode, useLayout, useScrolling, useSections, useVehiclePhotos } from "./Hooks";

import { Logo, Menu } from "./components";
import { Carousel, CtaBanner } from "../../components";
import { Divider } from "../../components/Divider";
import { VehDetail, VehPhotos, DealerSection, DealerBtn, DealerSocials, Documents, Salesperson, SalesPersonBtn } from "./components";
import { Header } from "./components/Header/Header";

import "./Layout.scss";
import { SalespersonMessage } from "./components/Salesperson/Salesperson";

export const Layouts = (page: VINFO.Page) => {
    const [section, setSection] = useState(ENUMS.VinfoSection.vehicle);
    const prefersDark = useDarkMode(page.vinfo.theme);
    const layoutProps: VINFO.Layout = { page, section, setSection, prefersDark };
    return useLayout(layoutProps);
}

export const Desktop = (layout: VINFO.Layout) => {
    const photos = useVehiclePhotos(layout.page.vinfo.inventory);
    const prefersDark = useDarkMode(layout.page.vinfo.theme);

    return (
        <div className="vinfo-desktop">
            <div className="overlay-img" />
            <div className="overlay" />
            <div className="content gutters vh flexblock cols gap-hundo">
                <div className="flexblock rows center gap-hundo">
                    <div className="flex-col sections">
                        <Logo {...layout.page} />
                        <div className="contact-btns flexblock rows center gap-thirty">
                            <DealerBtn {...layout} />
                            <SalesPersonBtn {...layout} />
                        </div>
                        <Divider size="large" text="Documents" />
                        <Documents {...layout.page} />
                    </div>
                    <div className="flex-col">
                        <div className="pad flexblock gap-ten aln-end socials">
                            <DealerSocials size="medium" social_urls={layout?.page?.vinfo?.dealership?.social_urls} />
                        </div>
                        <div className={Layout.VinfoBlock(layout.page.viewType, "grow vehicle-photos")}>
                            <Carousel
                                type={ENUMS.VinfoCarousel.standard}
                                items={photos}
                                itemkey="url"
                                altkeys={["stored_image_filename", "url"]}
                                preference={prefersDark ? "dark" : "light"} />
                        </div>
                        <br />
                        <div className={Layout.VinfoBlock(layout.page.viewType, "shaded rounded vehicle card")}>
                            <VehDetail {...layout.page} />
                        </div>
                    </div>
                </div>
            </div>
            <CtaBanner {...layout} />
        </div>
    );
};

export const Mobile = (layout: VINFO.Layout) => {
    const sections = useSections(layout);
    // useScrolling("VinfoContent", useMemo(() => sections, [sections]));
    return (
        <div className="content fill">
            <div className="flexblock cols stretch">
                <Header {...layout.page} />
                <div id="VinfoContent" className={Layout.VinfoBlock(layout.page.viewType, "slides")}>
                    <div {...sections[0]}>
                        <Salesperson {...layout.page} />
                    </div>
                    <div {...sections[1]}>
                        <div className={Layout.VinfoBlock(layout.page.viewType, "grow vehicle")}>
                            <VehPhotos {...layout.page} />
                            <VehDetail {...layout.page} />
                        </div>
                        <Documents {...layout.page} />
                        <div id="MobileDealerSocials" className="pad flexblock center gap-ten aln-cnt socials">
                            <DealerSocials size="large" social_urls={layout?.page?.vinfo?.dealership?.social_urls} />
                        </div>
                    </div>
                    <div {...sections[2]}>
                        <DealerSection {...layout} />
                    </div>
                </div>
                <SalespersonMessage {...layout.page} />
                <Menu {...layout} />
            </div>
        </div>
    );
};

export const FinePrint = () => {
    return (
        <span className="fineprint">
            <a href="https://www.loopmein.app/vinfo/" target="_blank" rel="noreferrer">About Vinfo</a>
            <span className="bulletdivider">•</span>
            <a href="https://www.loopmein.app/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a>
        </span>
    )
}
