import { Helpers } from "../../../../../utils";

export const Price = (props: VINFO.DetailLayout) => {
    const hasPrice = props.inventory.selling_price > 0;
    return (
        <h4 className="price">
            {hasPrice ? <span className="sub">Selling Price</span> : <span />}
            <strong>{hasPrice ? Helpers.formatDollar(props.inventory.selling_price) : <a href={`tel://+${props.dealership.sales_phone}`} className="call-price">Call for Price</a>}</strong>
            {hasPrice ? <span className="sub"><Fees {...props} /></span> : <span />}
        </h4>
    )
}

const Fees = ({ settings }: VINFO.DetailLayout) => {
    return <span>{settings?.price_supplemental ? <span dangerouslySetInnerHTML={{ __html: settings.price_supplemental }} /> : "+applicable fees & taxes"}</span>;
}